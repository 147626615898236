<template>
  <UiPopup
    :model-value="modelValue"
    title="Test the phone number"
    description="Place a call from phone number to check its availability"
    primary-button-text="Call"
    size="small"
    @update:model-value="closePopup()"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputPhone
        v-model="data.from"
        name="Phone"
        label="Test number"
        placeholder="Add a number"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['from'])"
      />
      <UiInputPhone
        v-model="data.to"
        name="Phone"
        label="Contact for the test call"
        placeholder="Add a number"
        class="mb-6"
        :error="useGetFieldErrors(v$, ['to'])"
      />
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Phone } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

type Numbers = {
  from: Phone
  to: Phone
}

const data = ref<Numbers>({
  from: {
    phone: null,
    phone_country_id: null,
  },
  to: {
    phone: null,
    phone_country_id: 234,
  },
})

const validatePhone = (value: Phone) => {
  return useValidatePhone(value.phone, value.phone_country_id)
}

const rules = computed(() => ({
  from: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  to: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
}))

const v$ = useVuelidate(rules, data, { $stopPropagation: true })

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    emits('input', data.value)
    closePopup()
  }
}

const closePopup = () => {
  data.value = {
    from: {
      phone: null,
      phone_country_id: null,
    },
    to: {
      phone: null,
      phone_country_id: 234,
    },
  }
  emits('update:modelValue', false)
}
</script>
